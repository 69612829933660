import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { Report } from '../types';

export const getReports = (year = '2025'): Promise<Data<Report[]>> =>
  axios.get(`${API_BASE_PATH}/reports`, {
    params: {
      'filter[year]': year,
    },
  });

type QueryFnType = typeof getReports;

type UseReportsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useReports = ({ config }: UseReportsOptions = {}) => {
  const [searchParams] = useSearchParams();

  const year = searchParams.get('filter[year]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['reports', year],
    queryFn: () => getReports(year),
  });
};
